import React from "react";
import Property1 from "../../Assets/Images/Property1.png";
import Property2 from "../../Assets/Images/Property2.png";
import Property3 from "../../Assets/Images/Property3.png";
import About from "./About";
import OurService from "./OurService";
import WhyChooseUs from "./WhyChooseUs";
import OurClients from "./OurClients";
import Brochures from "./Brochures";
import ContactForm from "../ContactUs/ContactForm";
import CarouselMain from "../../Components/Carousel/CarouselMain";

const Home = () => {
  const carouselData = [
    {
      image: Property1,
      title: "Security Solutions Provider",
      text: "We specialize in surveillance systems, access control, and 24/7 monitoring  and efficient and reliable Manpower Supply solutions.",
      navigate: "security",
    },
    {
      image: Property2,
      title: "Facility Management",
      text: "Excellence in every detail, ensuring your facilities run smoothly.",
      navigate: "facility",
    },
    {
      image: Property3,
      title: "Smart Operations",
      text: "Smart operations streamline workflows and enhance efficiency through innovative technology solutions, optimizing performance and reducing costs for businesses of all sizes.",
      navigate: "operations",
    },
  ];

  return (
    <div className="overflow-hidden">
      <CarouselMain items={carouselData} />
      <About />
      <OurService />
      <WhyChooseUs />
      <OurClients />
      <Brochures />
      <ContactForm />
    </div>
  );
};

export default Home;
