import React, { useEffect } from "react";
import GooglMap from "../../Components/GooglMap/GooglMap";

const ContactForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="px-3 py-5 bg-primary-light-theme">
      <div className="container">
        <div className="row gx-5 gy-3">
          <div className="col-md-6">
            <div className="d-flex flex-column gap-3">
              <p className="text-center text-primary-theme h2 mb-0">
                Contact Us Now
              </p>

              {/* <div className="w-100 h-800p position-relative overflow-hidden">
                <div
                  className="position-absolute translate-middle w-100"
                  style={{ top: "295px", left: "50%" }}
                > */}

              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfQsYQGUo1vQATTHkIoc_iqMu7BEAvowF5S0XP_mcAdhAyuxg/viewform?embedded=true"
                title="Google Form"
                width="100%"
                height="1000px"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                style={{ border: "none" }}
                allowFullScreen
              >
                Loading…
              </iframe>

              {/* </div>
              </div> */}
            </div>
          </div>
          <div className="col-md-6">
            <p className="text-center text-primary-theme h2 mb-3">Our Office</p>
            <GooglMap />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
