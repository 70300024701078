import React, { useEffect } from "react";
import bannerImg from "../../Assets/Images/page 2 img.png";
import lock from "../../Assets/SVG/lock.svg";
import facility from "../../Assets/SVG/facility.svg";
import operations from "../../Assets/SVG/operations.svg";
import staffTraining from "../../Assets/SVG/StaffTraining.svg";
import Industries from "./Industries";

const Solutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden">
      <img
        src={bannerImg}
        alt=""
        className="w-100 h-350p img-cover-center"
        loading="lazy"
      />
      <div className="d-flex align-items-center justify-content-center gap-5 bg-primary-theme text-white p-3">
        <a href="#security" className="text-white pointer">
          Security Solutions
        </a>
        <a href="#facility" className="text-white pointer">
          Facility Management Solution
        </a>
        <a href="#operations" className="text-white pointer">
          Smart Operations
        </a>
      </div>
      <div className="container py-5 px-3">
        <div
          id="security"
          className="row g-3 align-items-center col-reverse-md"
        >
          <div className="col-md-8">
            <p className="h4">
              StarkSpace&nbsp;
              <span className="text-primary-theme">Security Solutions</span>
            </p>
            <p>
              Starkspace is a leading provider of smart security facility
              management services, offering cutting-edge solutions to ensure the
              safety and efficiency of your premises. Our state-of-the-art
              technology and dedicated team provide 24/7 monitoring, rapid
              response, and comprehensive security management tailored to meet
              the unique needs of each client.
            </p>
          </div>
          <div className="col-md-4 text-center">
            <img
              src={lock}
              alt=""
              className="h-240p img-contain-center"
              loading="lazy"
            />
          </div>
        </div>
        <div id="facility" className="row g-3 align-items-center">
          <div className="col-md-4 text-center">
            <img
              src={facility}
              alt=""
              className="h-240p img-contain-center"
              loading="lazy"
            />
          </div>
          <div className="col-md-8">
            <p className="h4">
              StarkSpace&nbsp;
              <span className="text-primary-theme">Facility Management</span>
            </p>
            <p>
              Starkspace offers comprehensive facility management services
              designed to enhance the safety, functionality, and efficiency of
              your properties. Our team ensures that all aspects of your
              facility are maintained to the highest standards, providing a
              seamless and secure environment for your operations
            </p>
          </div>
        </div>
        <div
          id="operations"
          className="row g-3 align-items-center col-reverse-md"
        >
          <div className="col-md-8">
            <p className="h4">
              StarkSpace&nbsp;
              <span className="text-primary-theme">Smart Operations</span>
            </p>
            <p>
              Starkspace leverages advanced technology and innovative strategies
              to deliver smart operations, ensuring optimal performance and
              efficiency for your facilities. Our integrated systems and
              proactive approach enable real-time monitoring and management,
              providing seamless and intelligent solutions tailored to your
              operational needs.
            </p>
          </div>
          <div className="col-md-4 text-center">
            <img
              src={operations}
              alt=""
              className="h-240p img-contain-center"
            />
          </div>
        </div>
        <div id="staff_training" className="row g-3 align-items-center">
          <div className="col-md-4 text-center">
            <img
              src={staffTraining}
              alt=""
              className="h-240p img-contain-center"
              loading="lazy"
            />
          </div>
          <div className="col-md-8">
            <p className="h4">
              StarkSpace&nbsp;
              <span className="text-primary-theme">
                Staff Training & Modernization
              </span>
            </p>
            <p>
              Our Staff Training and Modernization feature is designed to
              elevate your company's workforce to the next level. This
              comprehensive program focuses on continuous professional
              development, ensuring your staff is equipped with the latest
              skills and knowledge to excel in their roles.
            </p>
          </div>
        </div>
      </div>
      <Industries />
    </div>
  );
};

export default Solutions;
