import React from "react";
import ComponentHeader from "../../Components/ComponentHeader/ComponentHeader";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <ComponentHeader title="Privacy Policy" />

      <div className="d-flex flex-column gap-3 py-5 px-3">
        <p>
          This Stark Space Privacy Policy explains what you can expect regarding
          the collection and use of personal information by Stark Space Inc. and
          its subsidiaries and affiliates listed here (as applicable,
          hereinafter each separately and/or jointly called the "Data
          Controller", “We”, “Our” or “Us”).
        </p>
        <p>
          In general, our privacy practices conform with local law and
          regulation, including where applicable the provisions of the European
          Union’s General Data Protection Regulation (GDPR). Accordingly, our
          privacy practices may vary among the countries in which we operate to
          reflect local practices and legal requirements.
        </p>
        <p>
          Based on our relationship with you, we may also provide ‘just in time’
          privacy notices that are tailored to the specific
          services/interactions you are eligible for, or receiving, at that
          particular time.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
