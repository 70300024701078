import React from "react";
import ComponentHeader from "../../Components/ComponentHeader/ComponentHeader";
import industrial from "../../Assets/Images/Industrial.png";
import commercial from "../../Assets/Images/Commercial.png";
import residential from "../../Assets/Images/Residential.png";
import airports from "../../Assets/Images/Airports.png";
import government from "../../Assets/Images/Government.png";

const Industries = () => {
  const cardArray = [
    { title: "Industrial", img: industrial },
    { title: "Commercial", img: commercial },
    { title: "Residential", img: residential },
    { title: "Airports", img: airports },
    { title: "Government", img: government },
  ];
  return (
    <div className="d-flex flex-column gap-3 bg-primary-light-theme py-5">
      <ComponentHeader title="Industries we Serve" />
      <div className="border-end border-primary-theme p-3">
        <div className="container">
          <div className="row g-3 justify-content-evenly">
            {cardArray?.map((item) => {
              return (
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="card rounded-4 overflow-hidden">
                    <img
                      src={item?.img}
                      className="w-100 h-190p img-cover-center"
                      alt=""
                    />
                    <div className="card-body bg-primary-theme text-white">
                      <p className="w-100 text-truncate text-center">
                        {item?.title}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industries;
