import React from "react";

const ComponentHeader = ({ title }) => {
  return (
    <div className="component-header">
      <p className="text-primary-theme border-primary-theme px-3 fw-bold mb-0 component-header-text">
        {title}
      </p>
      <p className="fw-bold mb-0 text-primary-theme component-header-title">
        {title}
      </p>
    </div>
  );
};

export default ComponentHeader;
