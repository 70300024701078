import React from "react";
import ComponentHeader from "../../Components/ComponentHeader/ComponentHeader";

const OurClients = () => {
  return (
    <div className="d-flex flex-column gap-3 bg-primary-light-theme py-5">
      <ComponentHeader title="Our Clients Speak For Us" />
      <div className="border-end border-primary-theme p-3">
        <div className="container">
          <div className="row align-items-end g-3">
            <div className="col-md-4">
              <div className="card rounded-4 bg-white text-dark">
                <div className="card-body">
                  <p className="mb-3">
                    Inquiring facility management was seamless and efficient.
                    The user-friendly website offered various options, and the
                    quick confirmation email provided peace of mind. A
                    hassle-free experience from start to finish.
                  </p>
                  <p>-Technixia</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded-4 bg-white text-dark shadow-sm mb-md-3rem">
                <div className="card-body">
                  <p className="mb-3">
                    I am highly satisfied with the service provided by your
                    facility management company. Your team has consistently
                    demonstrated exceptional professionalism and efficiency in
                    maintaining our premises.
                  </p>
                  <p>-Sociohood Technologies</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded-4 bg-white text-dark">
                <div className="card-body">
                  <p className="mb-3">
                    Your facility management services have been outstanding. The
                    team is always prompt and efficient, ensuring our property
                    is well-maintained.
                  </p>
                  <p>-Alka Poorna Agency</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
