import React from "react";
import facility from "../../Assets/Images/facility-management_10962225.png";
import money from "../../Assets/Images/Money Icon.I01.2k.png";
import chart from "../../Assets/Images/3D Chart Diagram.H09.2k.png";
import users from "../../Assets/Images/image-removebg-preview (4).png";
import ComponentHeader from "../../Components/ComponentHeader/ComponentHeader";
import { useNavigate } from "react-router-dom";

const OurService = () => {
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column gap-3 bg-primary-light-theme py-5">
      <ComponentHeader title="Our Service" />
      <div className="border-end border-primary-theme p-3">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-3 col-md-6">
              <div className="d-flex flex-column align-items-center gap-3 px-3 h-100">
                <div className="rounded-10 h-90p w-90p inner-shadow p-2 bg-white">
                  <img
                    src={facility}
                    alt=""
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
                <p className="text-secondary">
                  Facility Management & Software Program
                </p>
                <span
                  className="d-flex align-items-center gap-2 text-primary-theme mt-auto pointer"
                  onClick={() => navigate("/solutions")}
                >
                  <p>Learn More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="d-flex flex-column align-items-center gap-3 px-3 h-100">
                <div className="rounded-10 h-90p w-90p inner-shadow p-2 bg-white">
                  <img
                    src={money}
                    alt=""
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
                <p className="text-secondary">Visualized Facility Management</p>
                <span
                  className="d-flex align-items-center gap-2 text-primary-theme mt-auto pointer"
                  onClick={() => navigate("/solutions")}
                >
                  <p>Learn More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="d-flex flex-column align-items-center gap-3 px-3 h-100">
                <div className="rounded-10 h-90p w-90p inner-shadow p-2 bg-white">
                  <img
                    src={chart}
                    alt=""
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
                <p className="text-secondary">Facility Operations Analysis</p>
                <span
                  className="d-flex align-items-center gap-2 text-primary-theme mt-auto pointer"
                  onClick={() => navigate("/solutions")}
                >
                  <p>Learn More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="d-flex flex-column align-items-center gap-3 px-3 h-100">
                <div className="rounded-10 h-90p w-90p inner-shadow p-2 bg-white">
                  <img
                    src={users}
                    alt=""
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
                <p className="text-secondary">Staff Training & Modernization</p>
                <span
                  className="d-flex align-items-center gap-2 text-primary-theme mt-auto pointer"
                  onClick={() => navigate("/solutions")}
                >
                  <p>Learn More</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
