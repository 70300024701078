import React from "react";
import ComponentHeader from "../../Components/ComponentHeader/ComponentHeader";

const About = () => {
  return (
    <div className="d-flex flex-column gap-3 py-5">
      <ComponentHeader title="About Us" />
      <div className="border-end border-primary-theme p-3">
        <div className="container">
          <p style={{ fontSize: "18px", lineHeight: "32px" }}>
            Starkspace is a leading provider of smart security facility
            management services. We enhance the safety and efficiency of your
            properties through advanced technology and innovative strategies.
            Our team ensures seamless and secure operations tailored to your
            needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
