import React from "react";
import ContactForm from "./ContactForm";
import bannerImg from "../../Assets/Images/page 3.png";


const ContactUs = () => {
  return (
    <>
      <img src={bannerImg} alt="" className="w-100 h-300p img-cover-center" loading="lazy" />
      <ContactForm />
    </>
  );
};

export default ContactUs;
