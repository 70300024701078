import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoBlack from "../../Assets/Logo/logo-black.png";

const Header = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  React.useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const isActive = (link) => {
    return link === activeLink ? "text-primary-theme" : "";
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light sticky-header bg-white">
      <div className="container-fluid container">
        <div className="d-flex align-items-center gap-2">
          <img className="h-40p w-40p" src={logoBlack} alt="" loading="lazy" />
          <Link className="h4 mb-0 text-primary-theme" to="/">
            Stark Space
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/")}`}
                aria-current="page"
                to="/"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/solutions")}`}
                to="/solutions"
              >
                Solutions
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/contact-us")}`}
                to="/contact-us"
              >
                Contact Us
              </Link>
            </li>
            {/* <li className="nav-item">
              <button className="btn btn-primary-theme">
                <div className="d-flex align-items-center gap-2">
                  <p>Register Now</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </div>
              </button>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
