import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const CarouselMain = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const renderTitle = (title) => {
    const words = title.split(" ");
    const lastWord = words.pop();
    return (
      <p className="carousel-title h1 fw-bold">
        {words.join(" ")} <span className="last-word">{lastWord}</span>
      </p>
    );
  };

  return (
    <Slider {...settings}>
      {items?.map((item, index) => (
        <div key={index} className="carousel-slide">
          <div
            className="carousel-background py-5 px-responsive"
            style={{ backgroundImage: `url(${item?.image})` }}
          >
            <div className="col-lg-4 col-md-6 d-flex flex-column justify-content-center gap-4 h-100">
              {renderTitle(item?.title)}
              <p className="carousel-text h5">{item?.text}</p>
              <div className="d-flex justify-content-start">
                <Link
                  className="btn btn-outline-primary-theme rounded-4"
                  to={`/solutions#${item?.navigate}`}
                >
                  Know more
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

const NextArrow = ({ onClick }) => {
  return (
    <div className="slick-next" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-right"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
        />
      </svg>
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="slick-prev" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
        />
      </svg>
    </div>
  );
};

export default CarouselMain;
