import React from "react";

const GooglMap = () => {
  return (
    <div className="iframe-wrapper">
      <iframe
        id="my-response-iframe"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.077416874759!2d85.15156647590885!3d25.602343615170597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed59693ee5e873%3A0x5ee6cf4ecc36800c!2sSTARKSPACE%20MANAGEMENT%20SOLUTIONS%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1721117379679!5m2!1sen!2sin"
        height="100%"
        width="100%"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GooglMap;
