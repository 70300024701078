import React from "react";
import ComponentHeader from "../../Components/ComponentHeader/ComponentHeader";
import pdfImg from "../../Assets/Images/pdf.png";
import starkSpaceBrochure from "../../Assets/PDF/StarkSpaceBrochure.pdf";
import starkSpaceLongBrochure from "../../Assets/PDF/StarkSpaceLongBrochure.pdf";

const Brochures = () => {
  const handleLongBrochureDownload = async () => {
    const link = document.createElement("a");
    link.href = starkSpaceLongBrochure;
    link.download = "brochure.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShortBrochureDownload = async () => {
    const link = document.createElement("a");
    link.href = starkSpaceBrochure;
    link.download = "brochure.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="d-flex flex-column gap-3 py-5">
      <ComponentHeader title="Brochures" />
      <div className="border-end border-primary-theme p-3">
        <div className="container">
          <div className="row align-items-end gx-5 gy-3">
            <div className="col-md-4">
              <div className="card rounded-4 bg-white text-dark border">
                <div className="card-body d-flex flex-column gap-3 align-items-center">
                  <img src={pdfImg} alt="" className="mw-150p" loading="lazy" />
                  <p className="text-secondary">Long Brochure</p>
                  <span
                    className="btn btn-outline-primary-theme rounded-4 d-flex align-items-center gap-2"
                    onClick={handleLongBrochureDownload}
                  >
                    Download
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded-4 bg-white text-dark border">
                <div className="card-body d-flex flex-column gap-3 align-items-center">
                  <img src={pdfImg} alt="" className="mw-150p" loading="lazy" />
                  <p className="text-secondary">Short Brochure</p>
                  <span
                    className="btn btn-outline-primary-theme rounded-4 d-flex align-items-center gap-2"
                    onClick={handleShortBrochureDownload}
                  >
                    Download
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card rounded-4 bg-white text-dark border">
                <div className="card-body d-flex flex-column gap-3 align-items-center">
                  <img src={pdfImg} alt="" className="mw-150p" loading="lazy" />
                  <p className="text-secondary">Long Brochure</p>
                  <span
                    className="btn btn-outline-primary-theme rounded-4 d-flex align-items-center gap-2"
                    onClick={handleLongBrochureDownload}
                  >
                    Download
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brochures;
